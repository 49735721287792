.popups {
  position: fixed;
  max-height: calc(100vh - 100px);
  top: 100px;
  right: 26px;
  max-width: 384px;
  width: 100%;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
}

.popup {
  position: relative;
  overflow: hidden;
  margin: 16px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px rgba(0, 0, 0, 0.05);
}

.closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
}

:global {
  .popup {
    @mixin effect {
      animation-duration: 0.24s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;
    }

    &-fade-enter,
    &-fade-appear {
      @include effect;

      opacity: 0;
      animation-play-state: paused;
    }

    &-fade-leave {
      @include effect;

      animation-duration: 0.2s;
      animation-play-state: paused;
    }

    &-fade-enter,
    &-fade-enter-active,
    &-fade-appear,
    &-fade-appear-active {
      animation-name: NotificationFadeIn;
      animation-play-state: running;
    }

    &-fade-leave,
    &-fade-leave-active {
      animation-name: NotificationFadeOut;
      animation-play-state: running;
    }
  }
}

@media screen and (max-width: 767px) {
  .popups {
    top: 0px;
    right: 0px;
  }
}
