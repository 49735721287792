.languageSwitch {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  .switchBtn {
    width: 70px;
    height: 40px;
    // border-radius: 20px;
    background-color: #fff;
    @media screen and (max-width: 1200px) {
      width: 35px;
      min-width: 35px;
      height: 24px;
      border-radius: 20px;
    }
  }

  :global(.ant-switch) {
    :global(.ant-switch-handle) {
      width: 32px;
      height: 32px;
      top: 4px;
      left: 4px;
      &::before {
        background-image: url(../../../../assets/images/lang/zh.png);
        background-size: cover;
        border-radius: 16px;
      }
      @media screen and (max-width: 1200px) {
        width: 20px;
        height: 20px;
        top: 2px;
        left: 2px;
      }
    }
  }

  :global(.ant-switch-checked) {
    :global(.ant-switch-handle) {
      left: calc(100% - 32px - 4px);
      &::before {
        background-image: url(../../../../assets/images/lang/en.png);
        background-size: cover;
      }
      @media screen and (max-width: 1200px) {
        left: calc(100% - 20px - 2px);
      }
    }
  }
}
