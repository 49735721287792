@import "~antd/dist/antd.less"; // 引入官方提供的 less 样式入口文件

// Global CSS Variables
:root {
  --text-color: #020202;
  --reverse-color: #fff;
  --primary-color: #314a9a;
  --primary-red: #eb4946;
  --disabled-color: #666;
  --border-radius-base: 8px;
  --card-padding-base: 20px;
  --divider-color: rgba(#7b7c98, 0.09);
  --collapse-header-padding-extra: 48px;
  --block-border-color: #e2e2e3;
  --widget-border-radius-base: 4px;
  --disabled-bg: #fafafb;
  --layer-bg: #e7ebf4;
  --text-description-color: #8d939d;
  --input-placeholder-color: #b8becc;
  --dark-disabled-color: rgba(175, 176, 178, 0.2);
  --header-border-color: rgba(#fff, 0.1);
  --nav-item-color: rgba(141, 147, 157, 0.7);
  --remark-color: rgba(0, 0, 0, 0.7);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --font-family-roboto: "roboto", var(--font-family-sans-serif);
  --component-background: #fafafb;
  --layout-header-height: 78px;
  --layout-header-padding: 0 46px 0 80px;
  @media screen and (max-width: 1200px) {
    --layout-header-height: 44px;
    --layout-header-padding: 0 16px;
  }
  @media screen and (max-width: 767px) {
    --card-padding-base: 20px 12px;
  }
}

@text-color-inverse: #e7ebf4;
// LINK
@link-color: var(--text-color);

// Base Scaffolding Variables
@primary-color: #314a9a;
@text-color: var(--text-color);

// Tooltip
// ---
// Tooltip background color
@tooltip-bg: var(--layer-bg);

// Layer background
@popover-background: var(--layer-bg);
@modal-content-bg: #ffffff;
@body-background: #ffffff;
@component-background: #fff;
@disabled-bg: var(--disabled-bg);

@box-shadow-base: 0 2px 8px rgb(0 0 0 / 15%);

@border-radius-base: var(--border-radius-base);
@item-hover-bg: #1e1f27;
@disabled-color: #696e76;
@text-color-secondary: #8d939d;

// height rules
@height-base: 44px;
@height-lg: 48px;
@height-sm: 40px;
// vertical padding for all form controls
@control-padding-horizontal: 12px;
@control-padding-horizontal-sm: 8px;

// Layout
@layout-header-background: #314a9a;
@layout-header-height: var(--layout-header-height);
@layout-header-padding: var(--layout-header-padding);
@layout-body-background: #ffffff;

// Divider
@divider-color: var(--divider-color);

// Button
@btn-primary-bg: #eb4946;
@btn-primary-color: #ffffff;
@btn-default-bg: #314a9a;
@btn-default-color: #ffffff;
@btn-default-border: rgba(#314a9a, 50%);
@btn-border-radius-base: 100px;
@btn-padding-horizontal-base: 22px;
@btn-line-height: 1;
@btn-height-base: 40px;
@btn-disable-color: #fff;
@btn-disable-bg: #c5cce3;
@btn-disable-border: #c5cce3;
@btn-height-sm: 24px;
@btn-font-size-sm: 12px;
@btn-border-radius-sm: 100px;
@btn-padding-horizontal-sm: 12px;
@btn-height-lg: 48px;
@btn-font-size-lg: 20px;
@btn-padding-horizontal-lg: 79px;
@btn-text-shadow: none;
@btn-primary-shadow: none;
@btn-ghost-color: @primary-color;
@btn-ghost-border: @primary-color;

// Dropdown
@dropdown-menu-bg: var(--layer-bg);

// Collapse
@collapse-header-padding: 24px;
@collapse-header-padding-extra: var(--collapse-header-padding-extra);
@collapse-content-padding: 24px;
@collapse-header-bg: @component-background;

// Input
@input-placeholder-color: var(--input-placeholder-color);

// Table
@table-border-color: transparent;
@table-header-bg: @component-background;
@table-header-cell-split-color: transparent;
@table-bg: var(--layer-bg);
@table-row-hover-bg: #1e1f27;
@table-header-sort-active-bg: transparent;
@table-fixed-header-sort-active-bg: transparent;
@table-header-sort-bg: null;
@table-body-sort-bg: transparent;

// ToolTip
@tooltip-color: @text-color-secondary;
@tooltip-bg: #383b47;

// Overwrite
.ant-tooltip-inner {
  --border-radius-base: 4px;
  padding: 12px;
  font-size: 12px;
  line-height: 14px;
}
.ant-popover-inner {
  border-radius: 4px;
}
.ant-popover-inner-content {
  color: var(--text-description-color);
}
.ant-input {
  --border-radius-base: var(--widget-border-radius-base);
}
.ant-input-borderless {
  background-color: var(--layer-bg) !important;
  & + .ant-input-group-addon {
    background-color: var(--layer-bg) !important;
  }
  &[disabled] {
    background-color: var(--disabled-bg) !important;
  }
}

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
  .ant-empty & {
    color: @disabled-color;
  }

  &:hover {
    > td {
      background: var(--layer-bg);
    }
  }
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-dashed):not([disabled]) {
  &:hover,
  &:focus {
    .button-color(
      @btn-default-color; ~`colorPalette("@{btn-default-bg}", 5) `;
          ~`colorPalette("@{btn-default-bg}", 5) `
      );
  }
}

@media screen and (max-width: 767px) {
  .ant-btn {
    padding: 8px;
  }
  .ant-btn-lg {
    font-size: 16px;
    line-height: 16px;
    height: 44px;
  }
}
