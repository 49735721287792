.card {
  color: var(--text-color);
  background: var(--component-background);
  border-radius: var(--border-radius-base);
  overflow: hidden;
}

.cardBody {
  padding: var(--card-padding-base);
}

.bordered {
  border: 1px solid var(--block-border-color);
}
