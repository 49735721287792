// Type
.primary {
  color: var(--primary-color);
}
.normal {
  color: #020202;
}
.reverse {
  color: var(--reverse-color);
}
.secondary {
  color: #afb0b2;
}
.description {
  color: #8d939d;
}
.danger {
  color: #c73b28;
}
.success {
  color: #27ca41;
}
.reward {
  color: #e94852;
}

// Size
.xxl {
  font-size: 34px;
  line-height: 38px;
}
.xl {
  font-size: 24px;
  line-height: 28px;
}
.lgr {
  font-size: 20px;
  line-height: 24px;
}
.lg {
  font-size: 18px;
  line-height: 26px;
}
.middle {
  font-size: 16px;
  line-height: 20px;
}
.small {
  font-size: 12px;
  line-height: 20px;
}

.x-small {
  font-size: 10px;
  line-height: 14px;
}

// Extra
.link {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.emphases {
  font-size: 26px;
  line-height: 30px;
}
.bold {
  font-weight: bold;
}

// Font Family
.system {
  font-family: var(--font-family-sans-serif);
}

.clickable {
  cursor: pointer;
}
