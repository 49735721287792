.footerContainer {
  width: 100%;
  min-height: 130px;
  text-align: center;
  position: relative;
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 100%;
  .socialItem {
    background: var(--layer-bg);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: var(--reverse-color);
    font-size: 32px;
    @media screen and (max-width: 1200px) {
      width: 32px;
      height: 32px;
      font-size: 22px;
    }
  }
  .redItem {
    background: rgba(202, 73, 6, 1);
    box-shadow: 2px 3px rgba(153, 34, 34, 1);
  }
  .blueItem {
    background: rgba(59, 76, 150, 1);
    box-shadow: 2px 3px rgba(58, 67, 127, 1);
  }
}
