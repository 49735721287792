.logo {
  display: flex;
  align-items: center;
  img:nth-child(1) {
    width: 108px;
    @media screen and (max-width: 1200px) {
      width: 64px;
    }
  }
}

.item {
  display: inline-block;
  padding: 0 20px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    color: #314a9a;
    background-color: #fff;
  }
  &.active {
    color: #314a9a;
    background-color: #fff;
  }
  &.disabled {
    pointer-events: none;
  }
}

.connectBtn {
  padding: 9px 19px;
  font-weight: 600;
  height: auto;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1200px) {
    padding: 6px 8px;
    height: 24px;
    line-height: 7px;
  }
}

.walletAddress {
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  background: rgba(79, 105, 189, 0.71);
  border-radius: 30px;
  height: 40px;
  padding: 0 16px;
  margin: 19px auto;
  @media screen and (max-width: 1200px) {
    padding: 4px 6px;
    height: auto;
    margin: auto;
  }
  svg {
    width: 20px;
    @media screen and (max-width: 1200px) {
      width: 12px;
    }
  }
  label {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.disconnectBtn {
  font-weight: 500;
  cursor: pointer;
  color: #020202;
}

.menuContainer {
  width: 166px;
  padding: 0;
  :global(.ant-dropdown-menu-item) {
    color: #020202;
  }
  :global(.ant-dropdown-menu-item-active) {
    color: #020202;
    background-color: #f2f5fb;
  }
  li {
    padding: 16px;
  }
  li:nth-child(1) {
    border-radius: var(--border-radius-base);
  }

  @media screen and (max-width: 1200px) {
    margin-top: 12px;
    width: 132px;
    padding: 0px;
    font-size: 15px;
    :global(.ant-dropdown-menu-item) {
      color: #000000;
      padding: 13px 16px;
    }
    :global(.ant-dropdown-menu-item-active) {
      color: #314a9a;
      background-color: rgba(49, 74, 154, 0.1);
      font-size: 14px;
      font-weight: bold;
    }
    li {
      padding: 0;
    }
  }
}

.mobileMenuContainer {
  margin-top: 12px;
  width: 132px;
  :global(.ant-dropdown-menu-item) {
    color: #000000;
  }
  :global(.ant-dropdown-menu-item-active) {
    color: #314a9a;
    background-color: rgba(49, 74, 154, 0.1);
  }
  li {
    padding: 0;
  }
}

.mobileItem {
  display: block;
  padding: 13px 16px;
  font-size: 15px;
  white-space: normal;
  &.hover {
    font-size: 14px;
    font-weight: bold;
  }
  &.active {
    color: #314a9a;
    background-color: rgba(49, 74, 154, 0.1);
    font-size: 14px;
    font-weight: bold;
  }
  &.disabled {
    pointer-events: none;
  }
}
