@keyframes NotificationFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
