.level-1 {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 0;
  color: #000000;
}
.level-2 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 0;
  color: #000000;
}
.level-3 {
  font-size: 24px;
  line-height: 24px;
}
.level-4 {
  font-size: 20px;
  line-height: 24px;
}

.level-5 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
  color: #000000;
}

.level-6 {
  font-size: 16px;
  line-height: 20px;
}

.inline-line-height {
  line-height: 1;
}

.weight-700 {
  font-weight: 700;
}

.weight-600 {
  font-weight: 600;
}

.weight-500 {
  font-weight: 500;
}
