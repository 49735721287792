.tag {
  display: inline-block;
  padding: 4px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--primary-color);
  background: rgba(49, 74, 154, 0.1);
  border-radius: 4px;
  cursor: default;
}
