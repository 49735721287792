// Custom Global Setting
body {
  font-family: var(--font-family-roboto);
}
button {
  user-select: none;
}

::-webkit-scrollbar {
  // width: 6px;
  // background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: none;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(255, 255, 255, 0.08);
}
