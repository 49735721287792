.title {
  font-size: 28px;
  line-height: 32px;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  color: var(--remark-color);
}

.walletList {
  margin-top: 35px;
  width: 100%;
}
