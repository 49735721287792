.close {
  background-color: var(--layer-bg);
  width: 28px;
  height: 28px;
  color: rgba(18, 18, 18, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  font-size: 12px;
  position: absolute;
  right: 24px;
  top: 24px;
}

.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 6px;
}

@media screen and (max-width: 767px) {
  .close {
    right: 16px;
    top: 16px;
  }
}
