.walletItem {
  cursor: pointer;
  width: 100%;
  .icon {
    width: 58px;
    height: 58px;
    object-fit: contain;
    border-radius: 50%;
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    color: #000;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .subtitle {
    color: #afb0b2;
    font-size: 14px;
    line-height: 20px;
  }

  &.disabled {
    cursor: not-allowed;
    .name,
    .network {
      color: #696e76;
    }
  }
}
