.container {
  height: 100%;
  overflow: hidden;
}

.header {
  border-bottom: 1px solid var(--header-border-color);
  width: 100%;
}

.content {
  position: relative;
  width: 100%;
  padding: 78px 0 0;
  // padding: 78px 16px 36px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 767px) {
  .content {
    padding: 28px 0 0;
  }
}
